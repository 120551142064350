import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import SEO from '../components/seo';
import Styles from './styles';
import Article from '../components/article';
import PageTitle from '../components/page-title';
import { Galleries, Cover } from '../components/galleries';
import { decodeHtml } from '../helpers';

function allGalleries(galleries) {
  const galleriesFiltered = galleries.filter((gallery) => {
    const {
      galleryData: { imagens },
    } = gallery;
    return imagens[0].imagem;
  });
  const galleriesSamples = galleriesFiltered.map((gallery) => {
    const {
      galleryData: { imagens },
    } = gallery;
    return (
      <Link to={`/galerias/${gallery.slug}`} key={gallery.slug}>
        <Cover image={imagens[0].imagem.sourceUrl}>
          <img alt="" />
          <figcaption>
            <small>
              <Moment locale="pt" format="DD/MM/YYYY">
                {gallery.date}
              </Moment>
            </small>
            <p>{decodeHtml(gallery.title)}</p>
            <div>
              <FontAwesomeIcon icon={faImages} />
              <span>{imagens.length}</span>
              {imagens.length === 1 ? 'Foto' : 'Fotos'}
            </div>
          </figcaption>
        </Cover>
      </Link>
    );
  });
  return galleriesSamples;
}

export default function GalleriesPage(props) {
  const {
    pageContext: { galleries },
  } = props;
  const published = galleries.filter(
    (gallery) => gallery.galleryData.hidden !== true,
  );

  return (
    <>
      <SEO title="Galerias" />
      <Styles />
      <Article as="section">
        <PageTitle>Galerias</PageTitle>
        <Galleries>{allGalleries(published)}</Galleries>
      </Article>
    </>
  );
}
