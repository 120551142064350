import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import { News } from './news';
import { decodeHtml } from '../helpers';

export const Galleries = styled(News)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 2rem;
  padding: 1rem;
  max-width: 100%;

  li {
    padding: 0;
  }
`;

export const Cover = styled.figure`
  margin: 0;
  background-image:
    linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), url("${({
      image,
    }) => image}");
  background-size: cover;
  height: 25rem;
  position: relative;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.1);
    z-index: 111;
  }
  figcaption {
    position: absolute;
    text-align: center;
    bottom: 5%;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    p {
      transition: color 0.25s ease-in-out;
      color: white;
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 1.7rem;
      min-height: 3.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 0.2rem;
    }
    div {
      span {
        margin: 0 0.2rem;
      }
      svg {
        margin: auto;
      }
    }
  }
`;

export function renderGalleries(galleries) {
  const galleriesFiltered = galleries
    .filter((gallery) => {
      const {
        galleryData: { imagens },
      } = gallery;
      return imagens[0].imagem;
    })
    .slice(0, 6);
  const galleriesSamples = galleriesFiltered.map((gallery) => {
    const {
      galleryData: { imagens },
    } = gallery;
    return (
      <li key={gallery.slug}>
        <Link to={`/galerias/${gallery.slug}`}>
          <Cover image={imagens[0].imagem.sourceUrl}>
            <img alt="" />
            <figcaption>
              <small>
                <Moment locale="pt" format="DD/MM/YYYY">
                  {gallery.date}
                </Moment>
              </small>
              <p>{decodeHtml(gallery.title)}</p>
              <div>
                <FontAwesomeIcon icon={faImages} />
                <span>{imagens.length}</span>
                {imagens.length === 1 ? 'Foto' : 'Fotos'}
              </div>
            </figcaption>
          </Cover>
        </Link>
      </li>
    );
  });

  return <Galleries>{galleriesSamples}</Galleries>;
}
